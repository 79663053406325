import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { requestHandler } from "../../utils/requestHandler";
import { ProductResponse } from "../../interfaces/productInterfaces";
import ProductCard from "./ProductCard";
import Pagination from "../../components/elements/Pagination";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";
function Products() {
  const navigate = useNavigate();
  const [productResponse, setProductsResponse] = useState<ProductResponse>({
    products: [],
    totalPages: 0,
    totalProducts: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [searchParams,setSearchParams]=useSearchParams()

  const fetchProducts = useCallback(async () => {
  console.log(searchParams.toString());
    setLoading(true);
    requestHandler<ProductResponse>({
      endpoint: `/api/v1/products/admin/all-products?${searchParams.toString()}`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setProductsResponse(res.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handlePageChange = (page: number) => {
    // setCurrentPage(page);
    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", page.toString()); // Update or add the parameter

    // Set the updated search parameters
    setSearchParams(newParams);
  };
  const deleteHandle = async (product_id: String) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        requestHandler({
          endpoint: `/api/v1/products/${product_id}`,
          method: "DELETE",
        })
          .then((res) => {
            fetchProducts()
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    });
  };


  const addToPopular = (id: string,status:boolean) => {
    requestHandler({
      endpoint: "/api/v1/products",
      method: "PUT",
      body: { isPopular: status, productId: id },
    })
      .then((res) => {
        if (res.success) {
          fetchProducts()
          Swal.fire({
            title: "Added!",
            text: `Your Product ${status?"added to ":"removed from"} popular list`,
            icon: "success",
          });          // resetForm();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Products </h2>
            {/* <p>Lorem ipsum dolor sit amet.</p> */}
          </div>
          
          <div>
            <button
              className="btn btn-primary btn-sm rounded"
              onClick={() => navigate("/create-product")}
            >
              Create new
            </button>
          </div>
        </div>
        <div className="card mb-4">
          {/* <header className="card-header">
            <div className="row gx-3">
              <div className="col-lg-4 col-md-6 me-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-control"
                />
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>All category</option>
                  <option>Electronics</option>
                  <option>Clothings</option>
                  <option>Something else</option>
                </select>
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Latest added</option>
                  <option>Cheap first</option>
                  <option>Most viewed</option>
                </select>
              </div>
            </div>
          </header> */}
          {/* card-header end// */}
          <div className="card-body">
            <div className="row gx-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5">
              {productResponse.products.map((product) => {
                return (
                  <ProductCard
                    key={product._id}
                    product={product}
                    onDelete={deleteHandle}
                    addToPopular={(id)=>addToPopular(id,!Boolean(product.isPopular))}
                  />
                );
              })}

             {productResponse.products.length ===0 && <p>No Products found</p>}

              {/* col.// */}
            </div>
            {/* row.// */}
          </div>
          {/* card-body end// */}
        </div>
        {/* card end// */}
        <Pagination
          currentPage={Number(searchParams.get("page"))||1}
          totalPages={productResponse.totalPages}
          onPageChange={handlePageChange}
        />
      </section>
    </Layout>
  );
}

export default Products;
