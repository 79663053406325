import React from "react";
import { Product } from "../../interfaces/productInterfaces";
import { Link } from "react-router-dom";

function ProductCard({
  product,
  onDelete,
  addToPopular
}: {
  product: Product;
  onDelete: (number: String) => void;
  addToPopular:(number:string)=>void
}) {

  return (
    <div className="col">
      <div className="card card-product-grid">
        <i
          className="icon material-icons md-star"
          style={{ cursor: "pointer" ,color:product.isPopular?"yellow":""}}
          onClick={() => addToPopular(product._id)}
        />

        <div className="img-wrap">
          {" "}
          <img
            src={product.images[0] || "assets/imgs/items/1.jpg"}
            alt="Product"
          />{" "}
        </div>
        <div className="info-wrap">
          <div className="title text-truncate">{product.name}</div>
          <div className="price mb-2">
            {product.currency.currencyCode}
            {product.price}
          </div>
          {/* price.// */}
          <Link
            to={`/update-product/${product._id}`}
            className="btn btn-sm font-sm rounded btn-brand "
          >
            {" "}
            <i className="material-icons md-edit" /> Edit{" "}
          </Link>
          <button
            className="btn btn-sm font-sm btn-light rounded ml-5"
            onClick={() => onDelete(product._id)}
          >
            {" "}
            <i className="material-icons md-delete_forever" /> Delete{" "}
          </button>
        </div>
      </div>
      {/* card-product  end// */}
    </div>
  );
}

export default ProductCard;
